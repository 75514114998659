.PointDisplay {
    background-color: #232323;
    box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.3);
    position: absolute;
    top: 1vh;
    right: 1vh;
    border-radius: 5px;
    display: flex;
    align-items: center;
    letter-spacing: 1px;
    opacity: 0.85;
    text-align: center;
    z-index: 10;
}

.PointDisplay > div:not(.Divider) {
    padding: 10px;
    width: 100px;
}

.Quit {
    cursor: pointer;
}

.Divider {
    background-color: #aaa;
    height: 40px;
    width: 2px;
    padding: 0px;
}

.Data {
    font-weight: bold;
    font-size: 20px;
    letter-spacing: 2px;
}

.Text {
    font-weight: 400;
    margin-top: 3px;
    color: #ccc;
    font-size: 12px;
    text-transform: uppercase;
}