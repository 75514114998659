.ProgressBar {
    width: 100%;
    height: 50px;
	overflow: hidden;
	border-radius: 5px;
	background-color: #ccc;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: inset 2px 2px 5px rgb(0,0,0,0.3);
	border-radius: 100px;
}

.ProgressShadow {
	box-shadow: inset 2px 2px 7px rgb(0,0,0,0.8);
	width: 100%;
	height: 100%;
	border-radius: 100px;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
}

.Progress {
	position: absolute;
	top: 0;
	left: 0;
	background-color: #fc4a1a;
	border-radius: 100px;
    height: 100%;
	width: 100%;
	transition-property: transform;
	transition-duration: 1.5s;
	transform: translateX(-100%);
	z-index: 0;
}

.Points {
	position: relative;
	z-index: 1;
	text-align: center;
	font-weight: 400;
	font-size: 30px;
	text-transform: uppercase;
	color: white;
	text-shadow: 2px 2px 5px rgb(0,0,0,0.3);
}