.MapItem {
    color: #232323;
    height: 650px;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    background-size: cover;
    background-position: center;
    text-align: center;
    box-shadow: 2px 2px 5px rgba(0,0,0,0.5);
}

.MapItem h2 {
    margin: 50px auto;
    font-size: 36px;
    font-weight: 400;
    font-weight: bold;
    text-shadow: 1px 1px 5px rgba(0,0,0,0.8);
    position: relative;
    color: white;
    text-align: center;
}

.MapItem .Inner {
    height: 50%;
    width: 100.1%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.ImageContainer {
    width: 200px;
    height: 200px;
    margin: 0 auto;
    transform: translateY(-50%);
}

.ImageContainer.Bg {
    background-color: white;
    border-radius: 500px;
    padding: 5px;
}

.Logo {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.PlayButton {
    backdrop-filter: blur(10px);
    position: absolute;
    bottom: 0;
    padding: 50px 0;
    left: 0;
    right: 0;
    margin: 0 auto;
}