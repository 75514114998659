.Button {
    background-color: #fc4a1a;
    color: #ececec;
    padding: 10px 50px;
    border-radius: 5px;
    border: 2px solid #fc4a1a;
    font-weight: bold;
    outline: none;
    cursor: pointer;
    font-size: 24px;
    box-shadow: 2px 2px 5px rgba(0,0,0,0.3);
    will-change: transform;
    transition: transform 0.3s ease, color 0.3s, background-color 0.3s, border 0.3s;
}

.Button:hover {
    transform: scale(1.1);
    color: #fc4a1a;
    background-color: #ececec;
    border: 2px solid #fc4a1a;
}

.Secondary {
    background-color: #ececec;
    border-color: #ececec;
    color: #fc4a1a;
}

.Secondary:hover {
    border-color: #ececec;
}