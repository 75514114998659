.RoundResult {
    position: absolute;
    top: 0;
    left: 0;
    color: white;
    width: 100%;
    height: 100%;
    animation: zoomIn 0.5s ease;
    z-index: 11;
}

.MapContainer {
    height: 70%;
}

.Content {
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
}

.Content > * {
    margin: 20px 0;
}

.Data {
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 2px;
}

.Text {
    text-transform: uppercase;
    letter-spacing: 1px;
}

button {
    margin: 0 auto;
}

.Buttons > * {
    margin: 0 20px;
}

@keyframes zoomIn {
    from {
        opacity: 0;
        transform: scale(1.2);
    }
    to {
        opacity: 1;
        transform: none;
    }
}