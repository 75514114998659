.MapList {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;
    padding: 20px 0;
    z-index: 1;
}

.PanoramaWrapper {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    pointer-events: none;
    opacity: 0.5;
}

.Logo {
    text-align: center;
    margin: 60px;
    font-size: 68px;
    text-shadow: 4px 4px 6px rgb(0,0,0,0.5);
    letter-spacing: -3px;
}

.Logo .Mmo {
    color: #fc4a1a;
}

.Logo .Guesser {
    font-weight: 200;
}

@media(max-width: 1400px) {
    
}