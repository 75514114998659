.Plotter {
    position: absolute;
    bottom: 1vh;
    right: 1vh;
    width: 45vh;
    height: 35vh;
    opacity: 0.6;
    transition: opacity 0.3s, transform 0.3s;
    box-shadow: 0 0 15px 10px rgba(0,0,0,0.3);
    transform-origin: bottom right;
    border-radius: 5px;
    overflow: hidden;
    will-change: transform;
    z-index: 10;
}

.Plotter:hover {
    opacity: 1;
    transform: scale(1.5);
}

.PlotMap {
    height: 88%;
}

.Plotter button {
    display: block;
    width: 100%;
    height: 12%;
    border: none;
    outline: none;
    cursor: pointer;
    color: white;
    background-color: #fc4a1a;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    transition: background-color 0.3s;
}

.Plotter button:disabled {
    color: #ddd;
    background-color: #232323;
    opacity: 0.7;
    cursor: not-allowed;
}
