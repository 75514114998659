.PlotMap {
    width: 100%;
    height: 100%;
    user-select: none;
    overflow: hidden;
    cursor: default !important;
}

.LeafletContainer{
    width: 100%;
    height: 100%;
}
