* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Montserrat', sans-serif;
}
body {
	overflow: hidden;
	background-color: #232323;
	color: #eee;
	font-size: 1.2rem;
}

.leaflet-container {
	cursor: default !important;
	background-color: #232323;
}

.pnlm-about-msg {
	display: none !important;
}
