.PanoramaViewer {
    width: 100vw;
    height: 100vh;
    background-color: black;
    transition: opacity 1s ease, transform 1s ease;
    animation: zoomIn 1s ease;
    z-index: 5;
}

@keyframes zoomIn {
    from {
        opacity: 0;
        transform: scale(1.5);
    }
    to {
        opacity: 1;
        transform: none;
    }
}